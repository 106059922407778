<!--

https://stackoverflow.com/questions/48035310/open-a-vuetify-dialog-from-a-component-template-in-vuejs

-->


<template>
  <v.dialog v-model="show" :loading="loading" @delete="bntDetele">

    <template #title-left>{{ item_edit.owner ? 'Собственник' : 'Житель' }}</template>

    <template #actions>
      <v-btn  text :disabled="btnOK_disabled"  @click="closeOK">
        СОХРАНИТЬ
      </v-btn>
    </template>

    <v-row>
      <v-col cols="12">
        <v-radio-group
            v-model="item_edit.person.isCompany"
            row
        >
          <v-radio
              label="Частное лицо"
              :value="false"
          ></v-radio>
          <v-radio
              label="Компания"
              :value="true"
          ></v-radio>
        </v-radio-group>
        <!--                  <v-radio-->
        <!--                      label="Option 11111"-->
        <!--                      value="1"-->
        <!--                  ></v-radio>-->
      </v-col>

      <v-col cols="12">
        <v-text-field
            v-model="item_edit.person.fullName"
            :label="`${item_edit.person.isCompany ? 'Название' : 'Фамилия Имя Отчество'}`"
            :placeholder="`${item_edit.person.isCompany ? 'ООО Ромашка' : 'Иванов Иван Семенович'}`"
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
            v-model="item_edit.doc.rightNumber"
            label="Документ"
            placeholder="50:20:0020410:2357-50/215/2020-5"
        />
      </v-col>

      <v-col cols="6">
        <v-text-field
            v-model="item_edit.doc.rightRegDate"
            label="Дата документа"
            placeholder="2022-12-21"
        />
      </v-col>

<!--      <v-col cols="6">-->
<!--        <v-text-field-->
<!--            v-model="item_edit.doc.rightType"-->
<!--            label="Тип собственности"-->
<!--            placeholder="Собственность"-->
<!--        />-->
<!--      </v-col>-->

        <v-col cols="12" sm="5">
          <v-select label="Тип собственности"
                    v-model="item_edit.doc.rightType"
                    :items="params.right_types"
                    item-text="[1]"
                    item-value="[0]"
          ></v-select>
        </v-col>


      <v-col cols="6">
        <v-checkbox
            v-model="item_edit.owner"
            label="Владелец"
        ></v-checkbox>
      </v-col>
      <v-col cols="6">
        <v-checkbox
            v-model="item_edit.active"
            label="Действующий"
        ></v-checkbox>
      </v-col>
    </v-row>


  </v.dialog>
</template>

<script>

let normal_data = {}


export default {
  props: {value: Boolean, item: Object, params: Object},
  data: function () {
    return {
      item_edit: {person: {}, doc: {}, active:null, owner:null},
      btnOK_disabled: true,
      loading: false,
    }
  },
  created() {
    normal_data = this.$lib.copy(this.$data)
  },
  computed: {
    show: {
      get() {
        if (this.value) this.form_show()
        return this.value
      },
      set(value) {
        if (!this.value) return // отрабатываем только закрытие формы
        this.$emit('input', value) // чтобы родитель изменил переменную
        // this.form_close()
      }
    }
  },
  watch: {
    item_edit: {
      deep: true, // глубокое наблюдение за объектом
      handler(newValue, oldValue) {
        newValue; oldValue
        // console.log('watch', newValue)
        this.btnOK_disabled = !this.$lib.isDifferences(newValue, this.item)
      }
    }
  },
  methods: {
    bntDetele() {
      this.item.delete = true
      this.$axios.delete(`${this.$url.server_residents}${this.item.id}/`, {zLoader: [this, 'loading']})
          .then(response => {
            response
            this.item.deleted = true
            this.item.active = false
            this.show = false
          })

    },

    form_show() {
      console.log('params', this.params)
      this.$lib.restore(this.$data, normal_data)
      //// Заполням форму
      this.$lib.restore(this.item_edit, this.item)
      if (!this.item_edit.doc) this.item_edit.doc = {}
    },
    closeOK() {
      // используй this.$lib.differences(this.item_edit,this.item) для отправки только различий
      // console.log('compare', this.$lib.differences(this.item_edit, this.item))
      //
       let data = this.$lib.differences(this.item_edit, this.item)
       this.$axios.patch(`${this.$url.server_residents}${this.item.id}/`, data, {zLoader: [this, 'loading']})
           .then(response => {
             this.$lib.restore(this.item, response.data)
             this.show = false
           })
    },
    // form_close() {
    //
    // },
  },
  components: {
    "v.dialog": () => import('@/site/componets/dialog'),
  }
}
</script>
